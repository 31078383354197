import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useSessinStorageHook } from "../../../../../../hook/sessionStorage";
import { iPacient } from "../../../../../../types/interface";

function Pacient({ img, name, age, mapSteps, isMobile }: iPacient) {
	const toHtml = (text: any) => {
		return { __html: text + "" };
	};
	const mobile = {
		top: -220,
		left: 12
	}
	const {
		getLocalValue,
	}: any = useSessinStorageHook();
	return (
		<g>
			<defs>
				<pattern
					id="pacient"
					height="100%"
					width="100%"
					viewBox="0 0 166 166"
					fill="#ffffff">
					<image
						x="0%"
						y="0%"
						width="166"
						height="166"
						xlinkHref={require(`../../../../../../assets/images/pacients/${img}`)}></image>
				</pattern>
			</defs>
			<circle
				cx={isMobile ? mobile.left : mapSteps.profile.left}
				cy={isMobile ? mobile.top : mapSteps.profile.top}
				r="83"
				fill="#FFFFFF"
				strokeWidth="6"
			/>
			<circle
				cx={isMobile ? mobile.left : mapSteps.profile.left}
				cy={isMobile ? mobile.top : mapSteps.profile.top}
				r="83"
				fill="url(#pacient)"
				stroke="#7DBFBF"
				strokeWidth="6"
			/>
			<text
				className="pacient_map--step__name"
				x={isMobile ? mobile.left : mapSteps.profile.left}
				y={isMobile ? 116 + mobile.top : 116 + mapSteps.profile.top}
				width={50}
				textAnchor="middle"
				fill="#ffffff">
				<tspan x={isMobile ? mobile.left : mapSteps.profile.left} dangerouslySetInnerHTML={toHtml(name)}></tspan>
				<tspan x={isMobile ? mobile.left : mapSteps.profile.left} dy="1.2em" dangerouslySetInnerHTML={toHtml(age)}></tspan>
			</text>
		</g>
	);
}

export default Pacient;
