import "./styles.scss";

interface iMarketParam {
	keyPoint: string;
	active: boolean;
	isMobile: boolean
	number: number
}

function Marker(param: iMarketParam) {
	//console.log("Marker "+param.number+": "+param.keyPoint)
	return (
		<g>
			<circle r={param.isMobile ? 24 : 38} fill={param.active ? "#3B989B" : "#5D5FEF"} />
			<circle
				r={param.isMobile ? 16 : 24}
				stroke="white"
				strokeWidth="1"
				fill={param.active ? "#3B989B" : "#A0A3DB"}
			/>
			<circle
				r={param.isMobile ? 11 : 17}
				stroke="white"
				strokeWidth="1"
				fill="#ffffff"
			/>
			<animateMotion
				calcMode="linear"
				keyPoints={param.keyPoint}
				keyTimes="0; 1"
				rotate="auto">
				<mpath xlinkHref="#curve" />
			</animateMotion>
		</g>
	);
}

export default Marker;
