import "./styles.scss";
import { iPopups } from "../../../../../../../types/interface";

interface iDialog {
	openDialog: (boolean: boolean) => void;
	dataPopup: iPopups;
}

function Table({dataPopup}: iDialog) {
	const toHtml = (text: any) => {
		return { __html: text + "" };
	};
    // console.log(dataPopup)
    return (
        <div className="pacient_steps--dialog__table">
            <div className="pacient_steps--dialog__table--title">
                {dataPopup.title}
            </div>
            <div className={`pacient_steps--dialog__table--table ${dataPopup.className}`}>
                <table>
                    <thead>
                        <tr>
                            {dataPopup.description.thead.map((th: string, index: number) => {
                                return (
                                    <th key={index} dangerouslySetInnerHTML={toHtml(th)}></th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {dataPopup.description.tbody.map((itm: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        {
                                            itm.map((td: string, index: number) => {
                                                return <td key={index} dangerouslySetInnerHTML={toHtml(td)}></td>
                                            })
                                        }
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Table;