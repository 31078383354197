import Step from "../../Elements/Step";
import Marker from "../../Elements/Marker";
import Pacient from "../../Elements/Pacient";

function Mobile({
	mapSteps,
	img,
	name,
	age,
	mapFalse,
	stepActive,
	steps,
	getLocalValue,
	isAnsverUsed,
}: any) {
	const paramPoints = {
		stepSize: ((1 * (128)) / mapSteps.mapMobile.height),
		marketSize: ((1 * (48)) / mapSteps.mapMobile.height),
		m: ((1 * (15)) / mapSteps.mapMobile.height)
	};
	let offset = 0;
	return (
		<div className="svg__map svg__map-mobile">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={mapSteps.mapMobile.width}
				height={mapSteps.mapMobile.height}
				fill="none"
				overflow={"visible"}
				viewBox={`0 0 ${mapSteps.mapMobile.width} ${mapSteps.mapMobile.height}`}>
				<Pacient
					img={img}
					name={name}
					age={age}
					mapSteps={mapSteps}
					isMobile={true}
				/>
				<path
					d={mapSteps.mapMobile.path}
					stroke="white"
					strokeWidth={mapSteps.mapMobile.strokeWidth}
					strokeLinecap="round"
					strokeLinejoin="round"
					id="curve"
				/>
				<path
					transform={`translate(${mapSteps.lineMobile.positionY}, 0)`}
					d={mapSteps.lineMobile.path}
					stroke="#62CFD5"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="6"
				/>
				{steps?.map((step: any, index: number) => {
					if (index !== 0) offset = offset + (paramPoints.stepSize / 2 - paramPoints.marketSize / 2)
					return (
						<g key={index}>
							<Step
								keyPoint={`${offset <= 1 ? offset : 1}; ${offset <= 1 ? offset : 1}`}
								active={
									getLocalValue("pacient").step[index] ||
										index === 0 ||
										steps[index - 1].survey.length === isAnsverUsed(index - 1)
										? true
										: false
								}
								number={index + 1}
								mapFalse={mapFalse}
								stepActive={stepActive}
								isMobile={true}
								stepName={mapSteps.stepName}
							/>
							{
								index === 0 ? offset += (paramPoints.stepSize) : (offset += paramPoints.stepSize)
							}
							{step.survey?.map((sur: any, ind: number) => {
								if (ind === 0) offset = offset - paramPoints.stepSize / 2 + paramPoints.marketSize / 2 + paramPoints.m;
								return sur.popup.keyPoints ? (
									<>
										<Marker
											key={ind}
											number={ind}
											keyPoint={`${offset <= 1 ? offset : 1}; ${offset <= 1 ? offset : 1}`}
											active={isAnsverUsed(index) > ind ? true : false}
											isMobile={true}
										/>
										{
											offset += paramPoints.marketSize + paramPoints.m
										}

									</>
								) : null;
							})}
						</g>
					);
				})}
			</svg>
		</div>
	);
}

export default Mobile;
