import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";
import { useSessinStorageHook } from "../../../../../../hook/sessionStorage";

import "./styles.scss";

import { iPacient } from "../../../../../../types/interface";

interface iInfoPatientStep {
	pacient: iPacient;
	step: number | undefined;
}

function Information({ pacient, step }: iInfoPatientStep) {
	const { getLocalValue }: any = useSessinStorageHook();
	const toHtml = (text: any) => {
		return { __html: text + "" };
	};

	const results: any = [];
	let numIndex: number = 0;

	if (
		pacient.steps &&
		step !== undefined &&
		pacient.steps[step].patient.length
	) {
		if (
			pacient.steps[step].preQuestionPatient &&
			getLocalValue("step").dopPoints === false
		) {
			numIndex = pacient.steps[step].preQuestionPatient?.length || 0;
			pacient.steps[step].preQuestionPatient?.forEach((num, index) => {
				results.push(
					<AccordionItem key={index}>
						<AccordionItemHeading>
							<AccordionItemButton>
								{pacient.patient ? pacient.patient[num].title : null}
								<i></i>
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel
							style={{ maxHeight: 496 - 50 - numIndex * (40 + 12) + "px" }}>
							<div
								dangerouslySetInnerHTML={toHtml(
									pacient.patient ? pacient.patient[num].description : ""
								)}></div>
						</AccordionItemPanel>
					</AccordionItem>
				);
			});
		} else {
			numIndex = pacient.steps[step].patient.length;
			pacient.steps[step].patient.forEach((num, index) => {
				results.push(
					<AccordionItem key={index}>
						<AccordionItemHeading>
							<AccordionItemButton>
								{pacient.patient ? pacient.patient[num].title : null}
								<i></i>
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel
							style={{ maxHeight: 496 - 50 - numIndex * (40 + 12) + "px" }}>
							<div
								dangerouslySetInnerHTML={toHtml(
									pacient.patient ? pacient.patient[num].description : ""
								)}></div>
						</AccordionItemPanel>
					</AccordionItem>
				);
			});
		}
	}

	const TableDiagnosis = () => {
		return (
			<div className="table-wrap">
				<table>
					<thead>
						<tr>
							<th colSpan={3}>Лист уточненных диагнозов*</th>
						</tr>
						<tr>
							{pacient.steps && step !== undefined && pacient.steps[step]
								? pacient.steps[step].diagnosis.thead.map(
										(itm: any, index: number) => {
											return <th key={index}>{itm}</th>;
										}
								  )
								: null}
						</tr>
					</thead>
					<tbody>
						{pacient.steps && step !== undefined && pacient.steps[step]
							? pacient.steps[step].diagnosis.tbody.map(
									(itm: any, index: number) => {
										return (
											<tr key={index}>
												{itm.map((td: any, index: number) => {
													return (
														<td
															key={index}
															dangerouslySetInnerHTML={toHtml(td)}></td>
													);
												})}
											</tr>
										);
									}
							  )
							: null}
					</tbody>
				</table>
			</div>
		);
	};

	return (
		<div className="pacient_steps--content-info">
			<div className="pacient_steps--content-info__pacient">
				<div className="pacient_steps--content-info__pacient-photo">
					<img
						src={require(`../../../../../../assets/images/pacients/${pacient.img}`)}
						alt=""
					/>
				</div>
				<div className="pacient_steps--content-info__pacient-name">
					<span dangerouslySetInnerHTML={toHtml(pacient.name)}></span>
					<span dangerouslySetInnerHTML={toHtml(pacient.age)}></span>
				</div>
			</div>
			{pacient.steps?.length &&
			step !== undefined &&
			pacient.steps[step].patient.length ? (
				<Accordion allowZeroExpanded>{results}</Accordion>
			) : pacient.steps?.length &&
			  step !== undefined &&
			  pacient.steps[step].diagnosis ? (
				<TableDiagnosis />
			) : null}
		</div>
	);
}

export default Information;
