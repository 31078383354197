import "./styles.scss";
import { iPopups } from "../../../../../../../types/interface";

interface iDialog {
	openDialog: (boolean: boolean) => void;
	dataPopup: iPopups;
}

function Warning({ dataPopup, openDialog }: iDialog) {
	const toHtml = (text: any) => {
		return { __html: text + "" };
	};
	// console.log(dataPopup);

	const RenderContentPopup = ({ objects }: any) => {
		// console.log(objects);
		return objects.map((item: any, index: number) => {
			switch (item.tag) {
				case "p":
					return (
						<p key={index} dangerouslySetInnerHTML={toHtml(item.text)}></p>
					);
				case "ul":
					return (
						<ul key={index}>
							{item.text.map((li: any, index: number) => {
								return (
									<li key={index}>
										<span dangerouslySetInnerHTML={toHtml(li.li)}></span>
									</li>
								);
							})}
						</ul>
					);
				default:
					return <p>err</p>;
			}
		});
	};
	return (
		<div className="pacient_steps--dialog__warning">
			<div className="pacient_steps--dialog__warning--content">
                <div className="pacient_steps--dialog__warning--ico">
                    <b>!</b>
                </div>
				<div className="pacient_steps--dialog__warning--text">
					<RenderContentPopup objects={dataPopup.description.content} />
				</div>
			</div>
		</div>
	);
}

export default Warning;
