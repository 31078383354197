import "./styles.scss";
import { useSessinStorageHook } from "../../../../hook/sessionStorage";

import { iSpecialist } from "../../../../types/interface";
import { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";


function Pacients({
	className,
	img,
	finish,
	tooltip,
	pacients,
	setPacient,
	num,
	name,
	setSpecialist
}: iSpecialist) {
	const { setLocalValue, getLocalValue, resetGame }: any =
		useSessinStorageHook();
	const toHtml = (text: any) => {
		return { __html: text + "" };
	};
	const { trackPageView, trackEvent } = useMatomo()
	useEffect(() => {
		document.title = 'Game Hunter - ' + name;
		console.log('test7');
		trackPageView({
			documentTitle: 'Game Hunter - ' + name, // optional
			customDimensions: [
				{
					id: 2,
					value: name,
				},
			], // optional
		})
	}, []);
	const pacientSelect = (number: number | null) => {
		setLocalValue({ pacient: number });
		if (setPacient) setPacient(number);
	};

	const ifResult = (id: number | boolean) => {
		const result =
			id !== false
				? getLocalValue("specialist").pacient.find(
					(pacient: any) => pacient.points && pacient.id === id
				)
				: getLocalValue("specialist").pacient.find(
					(pacient: any) => pacient.points
				);
		return result;
	};

	const ifAllResults = () => {
		const lengthPacient = getLocalValue("specialist").pacient.map(
			(itm: any, index: number) => {
				if (itm.points) {
					return itm;
				}
				return false;
			}
		);
		console.log('1234')
		if (
			!lengthPacient.includes(false) &&
			lengthPacient.length === pacients.length
		) {
			return true;
		}
		return false;
	};
	return (
		<div className="step_game pacient_select">
			<div>
				<div className="container">
					<div
						className="step_game-header-back"
						onClick={() => setSpecialist(null)}>
						<i></i>
						<b>К выбору cпециалиста</b>
					</div>
					{!ifAllResults() ? <h2>Выберите пациента</h2> : null}
				</div>
				<div className="pacient_select__main">
					<div className="container">
						<div className="pacient_select__body">
							<div className={`pacient_select__specialist  ${className}`}>
								<div>
									{!ifResult(false) ? (
										<>
											{/* {tooltip ? (
												<div className="pacient_select__specialist--tooltip">
													<span
														dangerouslySetInnerHTML={toHtml(tooltip)}></span>
												</div>
											) : null} */}
											<img
												src={require(`../../../../assets/images/specialists/select-${img}`)}
												alt=""
											/>
										</>
									) : (
										<>
											{finish.text && ifAllResults() ? (
												<div className="pacient_select__specialist--tooltip">
													<span
														dangerouslySetInnerHTML={toHtml(
															finish.text
														)}></span>
												</div>
											) : null}
											<img
												src={require(`../../../../assets/images/specialists/finish-${img}`)}
												alt=""
											/>
										</>
									)}
								</div>
							</div>
							<div
								className={`pacient_select__pacients column-${pacients?.length}`}>
								<ul>
									{pacients?.map((pacient, index) => {
										return (
											<li
												onClick={() =>
													ifResult(index) ? null : pacientSelect(index)
												}
												key={index}
												className={`pacient_select__pacient ${className}`}>
												{ifResult(index) ? (
													<div className="pacient_select__pacient-result">
														Ваш результат: <b>{ifResult(index).points}</b>
													</div>
												) : null}
												<div className="pacient_select__pacient-image">
													<img
														src={require(`../../../../assets/images/pacients/${pacient.img}`)}
														alt=""
													/>
												</div>
												<div
													className={`pacient_select__pacient-descr ${ifResult(index) ? "diagnose" : ""
														}`}>
													<span>
														<b>
															<span
																dangerouslySetInnerHTML={toHtml(
																	pacient.name
																)}></span>
															<span
																dangerouslySetInnerHTML={toHtml(
																	pacient.age
																)}></span>
														</b>
														<br />
														{ifResult(index) ? (
															<span
																dangerouslySetInnerHTML={toHtml(
																	pacient.diagnose
																)}></span>
														) : (
															pacient.description
														)}
													</span>
												</div>
											</li>
										);
									})}
								</ul>
								{ifAllResults() ? (
									<div className="reset-game" onClick={() => resetGame()}>
										<span>Начать заново</span>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
				<div className="number-appr">
					<div className="container">{num}</div>
				</div>
			</div>
		</div>
	);
}

export default Pacients;
