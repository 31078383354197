import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './pages';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';



const instance = createInstance({
  urlBase: "https://takeda.matomo.cloud/",
  siteId: 66,
});
const root = ReactDOM.createRoot(
  document.getElementById('game-hanter') as HTMLElement
);

root.render(
  <MatomoProvider value={instance}>
    <App />
  </MatomoProvider>
);
/*
root.render(
  <App />
);*/