import "./styles.scss";
import { useSessinStorageHook } from "../../../../../hook/sessionStorage";
import { iPacient } from "../../../../../types/interface";

interface isMap {
	isMap: boolean;
	setPacient: (number: number | null) => void;
	mapFalse?: (number: boolean) => void;
	pacient: iPacient;
}

function HeaderGame({ isMap, setPacient, mapFalse, pacient }: isMap) {
	const { getLocalCalculate, getLocalValue }: any = useSessinStorageHook();

    const isStepUsed = (index: number) => {
        const result = getLocalValue('pacient')?.step.find((step: any) => step.id === index);
        return result
    }
    const isAnsverUsed = (index: number) => {
        const result = getLocalValue('pacient')?.step.find((step: any) => step.id === index);
        return result ? result.ansver.length : 0
    }
	return (
		<div className="container">
			<div className="step_game-header">
				<div
					className="step_game-header-back"
					onClick={() =>
						isMap ? setPacient(null) : mapFalse ? mapFalse(true) : ""
					}>
					<i></i>
					<b>{isMap ? "К выбору пациента" : "Маршрут пациента"} </b>
				</div>
				{!isMap ? (
					<div className="step_game-header-map">
						{pacient.steps?.map((step, index) => {
							return (
                                <>
									<div key={index} className={isStepUsed(index) ? "complite" : ""}>
										<b>{index + 1}</b>
									</div>
									{step.survey[0].popup.keyPoints ? (
										<ul>
											{step.survey.map((sur, ind) => {
												return sur.popup.keyPoints ? <li key={ind} className={isAnsverUsed(index) > ind ? "complite" : ""}></li> : null;
											})}
										</ul>
									) : null}
                                </>
							);
						})}
					</div>
				) : null}
				<div className="step_game-header-result">
					<span>Ваш результат:</span>
					<b>{getLocalCalculate()}</b>
				</div>
			</div>
		</div>
	);
}

export default HeaderGame;
