import HeaderGame from "../HeaderGame";
import "./styles.scss";
import { useState, useEffect } from "react";
import { useSessinStorageHook } from "../../../../../hook/sessionStorage";

import { iPacientObj } from "../../../../../types/interface";
import Desktop from "./Resizeble/Desktop";
import Mobile from "./Resizeble/Mobile";

function Map({ pacient, setPacient, setMap, onStepActive, num }: iPacientObj) {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const { img, name, age, steps, mapSteps } = pacient;
	const { getLocalValue }: any = useSessinStorageHook();

	const mapFalse = () => {
		if (setMap) setMap(false);
	};
	const stepActive = (step: number) => {
		if (onStepActive) onStepActive(step);
	};

	const isAnsverUsed = (index: number) => {
		const result = getLocalValue("pacient")?.step.find(
			(step: any) => step.id === index
		);
		return result ? result.ansver.length : 0;
	};
	const handleWindowResize = () => {
		setWindowWidth(window.innerWidth);
	};
	useEffect(() => {
		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	// const DesktopSVG = () => {
	// 	return (

	// 	);
	// };

	return (
		<div className="step_game pacient_map">
			<HeaderGame isMap={true} setPacient={setPacient} pacient={pacient} />
			{windowWidth > 767 ? <Desktop mapSteps={mapSteps} img={img} name={name} age={age} mapFalse={mapFalse} stepActive={stepActive} steps={steps} getLocalValue={getLocalValue} isAnsverUsed={isAnsverUsed} /> : <Mobile mapSteps={mapSteps} img={img} name={name} age={age} mapFalse={mapFalse} stepActive={stepActive} steps={steps} getLocalValue={getLocalValue} isAnsverUsed={isAnsverUsed} />}
			<div className="number-appr"><div className="container">{num}</div></div>
		</div>
	);
}

export default Map;
