import { useEffect, useState } from "react";

import "./styles.scss";
import Specialists from "../components/Specialists";
import { iSpecialists } from "../types/interface";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import React from "react";

const isLocal = true;

function App() {
	const { trackPageView, trackEvent } = useMatomo()

	const [data, setData] = useState<iSpecialists>(() => {
		if (isLocal) {
			return require("../assets/data.json").game;
		}
		fetch("data.json", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (myJson) {
				setData(myJson.game);
			});
	});
	return <>{data ? <Specialists specialists={data.specialists} /> : null}</>;
}

export default App;
