import "./styles.scss";

import { useSessinStorageHook } from "../../../hook/sessionStorage";

import { iSpecialists } from "../../../types/interface"
import { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

function SelectSpecialists({ specialists, setSpecialist, numbers }: iSpecialists) {
	const { setLocalValue } = useSessinStorageHook();
	const { trackPageView, trackEvent } = useMatomo()
	useEffect(() => {
		document.title = 'Game Hunter';
		//console.log('test8');
		trackPageView({
			documentTitle: 'Главная', // optional
		})
	}, []);
	const onSelectSpecialist = (number: number | null) => {
		setLocalValue({ "specialist": number });
		if (setSpecialist) setSpecialist(number);
	};

	return (
		<div className="step_game select_specialist">
			<div className="container">
				<h1>Выберите специальность</h1>
				<div className="select_specialist__title"></div>
				<ul className="select_specialist__buttons">
					{specialists.map((spec, index) => {
						return (
							<li key={index} className={spec.className} onClick={() => onSelectSpecialist(index)} >
								<img
									src={require(`../../../assets/images/specialists/${spec.img}`)}
									alt=""
								/>
								<div>
									<span>{spec.name}</span>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
			<div className="number-appr"><div className="container">{numbers ? numbers : null}</div></div>
		</div>
	);
}

export default SelectSpecialists;
