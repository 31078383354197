import Step from "../../Elements/Step";
import Marker from "../../Elements/Marker";
import Pacient from "../../Elements/Pacient";


function Desktop({mapSteps, img, name, age, mapFalse, stepActive, steps, getLocalValue, isAnsverUsed}: any) {
	return (
		<div className="svg__map">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={mapSteps.map.width}
				height={mapSteps.map.height}
				fill="none"
				overflow={"visible"}
				viewBox={`0 0 ${mapSteps.map.width} ${mapSteps.map.height}`}>
				<Pacient img={img} name={name} age={age} mapSteps={mapSteps} isMobile={false} />
				<path
					d={mapSteps.map.path}
					stroke="white"
					strokeWidth={mapSteps.map.strokeWidth}
					strokeLinecap="round"
					strokeLinejoin="round"
					id="curve"
				/>
				<path
					transform={`translate(0,${mapSteps.line.positionY})`}
					d={mapSteps.line.path}
					stroke="#62CFD5"
					strokeWidth="6"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				{steps?.map((step:any, index:number) => {
					return (
						<g key={index}>
							<Step
								keyPoint={step.keyPoints ? step.keyPoints : "0, 0"}
								active={
									getLocalValue("pacient").step[index] ||
									index === 0 ||
									steps[index - 1].survey.length === isAnsverUsed(index - 1)
										? true
										: false
								}
								number={index + 1}
								mapFalse={mapFalse}
								stepActive={stepActive}
                                isMobile={false}
                                stepName={mapSteps.stepName}
							/>
							{step.survey?.map((sur:any, ind:number) => {
								return sur.popup.keyPoints ? (
									<Marker
										key={ind}
                                        number={ind}
										keyPoint={
											sur.popup.keyPoints ? sur.popup.keyPoints : "0, 0"
										}
										active={isAnsverUsed(index) > ind ? true : false}
                                        isMobile={false}
									/>
								) : null;
							})}
						</g>
					);
				})}
			</svg>
		</div>
	);
}

export default Desktop;
