import HeaderGame from "../HeaderGame";
import "./styles.scss";
import { useSessinStorageHook } from "../../../../../hook/sessionStorage";

import { iPacientStepObj, iSpecialists } from "../../../../../types/interface";
import Information from "./Information";
import Ansvers from "./Ansvers";
import Dialog from "./Dialog";
import { useEffect, useState } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

function Steps({ pacient, setPacient, setMap, step, onStepActive, img, num }: iPacientStepObj) {
    const [popup, setPopup] = useState<number>();
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const { setLocalValue }: any = useSessinStorageHook();
    const { trackPageView, trackEvent } = useMatomo()
    const {
        getLocalValue,
    }: any = useSessinStorageHook();
    const [data] = useState<iSpecialists>(() => {
        return require("../../../../../assets/data.json").game;
    });
    useEffect(() => {
        document.title = 'Game Hunter - ' + pacient.name?.replaceAll("&nbsp;", " ") + ' ' + pacient.age?.replaceAll("&nbsp;", " ") + ' ' + (step ? step + 1 : 1) + ' этап';
        //console.log('test5');
        trackPageView({
            documentTitle: 'Game Hunter - ' + data.specialists[getLocalValue("specialist").id].name + ' - ' + pacient.name?.replaceAll("&nbsp;", " ") + ' ' + pacient.age?.replaceAll("&nbsp;", " ") + ' ' + (step ? step + 1 : 1) + ' этап', // optional
            customDimensions: [
                {
                    id: 2,
                    value: data.specialists[getLocalValue("specialist").id].name,
                },
                {
                    id: 3,
                    value: pacient.name?.replaceAll("&nbsp;", " ") + ' ' + pacient.age?.replaceAll("&nbsp;", " ")
                },
                {
                    id: 4,
                    value: (step ? step + 1 : 1)
                },
            ], // optional
        })
    }, []);
    const [sowAnsver, setSowAnswer] = useState<any>(false);

    const mapFalse = () => {
        if (setMap) setMap(true);
    };
    const openDialog = (boolean: boolean) => {
        setOpenPopup(boolean)
        if (sowAnsver) {
            setLocalValue(sowAnsver);
        }
    }
    useEffect(() => {
        if (!openPopup && sowAnsver) {
            //console.log(sowAnsver)
            setLocalValue(sowAnsver);
            setSowAnswer(false)
        }
        if (pacient.steps && step && pacient.steps[step] && pacient.steps[step].finish && sowAnsver) {
            setLocalValue(sowAnsver);
            setSowAnswer(false)
        }
    }, [openPopup, sowAnsver])

    const saveClickAnsver = (data: any) => {
        setSowAnswer(data)
    }
    const onStepNext = (number: number) => {
        let nextStep = ++number;
        if (pacient.steps && pacient.steps?.length - 1 >= nextStep) {
            setLocalValue({ "step": nextStep });
            if (onStepActive) onStepActive(nextStep);
            setMap(true)
        }
    }

    return (
        <div className="step_game pacient_steps">
            <HeaderGame isMap={false} setPacient={setPacient} mapFalse={mapFalse} pacient={pacient} />
            <div className="container pacient_steps--container">
                <div className="pacient_steps--content">
                    <Information pacient={pacient} step={step} />
                    <Ansvers onStepNext={onStepNext} pacient={pacient} step={step} img={img} setPopup={setPopup} openDialog={openDialog} setPacient={setPacient} saveClickAnsver={saveClickAnsver} />
                </div>
                {
                    pacient.steps && step && pacient.steps[step].notes ?
                        <div className="container pacient_steps--container-notes">{pacient.steps[step].notes}</div>
                        : null
                }
            </div>
            {
                openPopup && pacient.popups?.length && popup !== undefined ?
                    <Dialog openDialog={openDialog} setSowAnswer={setSowAnswer} dataPopup={pacient.popups[popup]} />
                    : null
            }
            <div className="number-appr"><div className="container">{num}</div></div>
        </div>
    );
}

export default Steps;
