import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useSessionStorage } from "usehooks-ts";
// import {iSpecialist} from "../types/interface";

// interface iLocalSet {
// 	specialist?: number | null;
// 	pacient?: number | null;
// 	step?: number | null;
// }

export const useSessinStorageHook = () => {
	const { trackPageView, trackEvent } = useMatomo()
	const [value, setValue] = useSessionStorage(
		"game-hanter",
		JSON.stringify({ specialist: [] })
	);

	const setLocalResult = (arg: any) => {
		const dataLocal = JSON.parse(value);
		const specialist = dataLocal.specialist.find((spec: any) => spec.used === true);
		const pacient = specialist.pacient.find((spec: any) => spec.used === true);
		pacient.diagnose = arg.diagnose;
		pacient.points = arg.points;
		setValue(JSON.stringify(dataLocal));
	}

	const setLocalDopPoints = (points: number) => {
		const dataLocal = JSON.parse(value);
		const specialist = dataLocal.specialist.find((spec: any) => spec.used === true);
		const pacient = specialist.pacient.find((spec: any) => spec.used === true);
		const step = pacient.step.find((step: any) => step.used === true);
		step.dopPoints = points;
		setValue(JSON.stringify(dataLocal));
	}

	const setLocalValue = (values: any) => {
		const keyObj = Object.keys(values);
		const dataLocal = JSON.parse(value);
		const setData = (object: any, key: any) => {
			const objectType = () => {
				switch (key) {
					case "specialist":
						return {
							id: values[key],
							used: true,
							pacient: [],
						};
					case "pacient":
						return {
							id: values[key],
							used: true,
							points: 0,
							diagnose: false,
							step: []
						};
					case "step":
						return {
							id: values[key],
							used: true,
							visit: true,
							dopPoints: false,
							ansver: [],
						};
					case "ansver":
						return {
							id: values[key],
							used: true,
							visit: true,
							points: values.point,
							results: []
						};
					case "results":
						return {
							used: true,
							points: values.results,
							diagnose: values.diagnose
						}
					default:
						console.log('Error')
						return {};
				}
			};
			Object.keys(object).some((k) => {
				if (k === key) {
					// console.log(object[k]);
					if (!object[k].length) {
						object[k].push(objectType());
						console.log(object[k]);
					} else {
						const objItems = object[k].map((obj: any) => {
							if (obj.id === values[keyObj[0]]) {
								obj.used = true;
								return true;
							} else {
								obj.used = false;
								return false;
							}
						});
						if (!objItems.includes(true)) {
							object[k].push(objectType());
						}
					}
					setValue(JSON.stringify(dataLocal));
					console.log(dataLocal)
					return true;
				}
				if (object[k] && typeof object[k] === "object") {
					setData(
						object[k].find((itm: any) => itm.used),
						key
					);
				}
				return false;
			});
		};

		console.log(dataLocal, keyObj[0])
		setData(dataLocal, keyObj[0]);
	};

	const getLocalValue = (key: string) => {
		const dataLocal = JSON.parse(value);
		const getData = (object: any, key: string) => {
			var value;
			Object.keys(object).some((k) => {
				if (k === key) {
					value = object[k].find((itm: any) => itm.used);
					return true;
				}
				if (object[k].length && typeof object[k] === "object") {
					value = getData(
						object[k].find((itm: any) => itm.used),
						key
					);
					return value !== undefined;
				}
				return false;
			});
			return value;
		};
		return getData(dataLocal, key);
	};

	const getLocalCalculate = () => {
		const pacient: any = getLocalValue('pacient');
		const result = pacient.step.map((itm: any) => {
			const ansver = itm.ansver.map((ansv: any) => {
				const points = ansv.points + (ansv.results.length ? ansv.results[0].points : 0);
				return points
			});
			return ansver.reduce((a: number, b: number) => a + b, 0) + itm.dopPoints
		})
		return result.reduce((a: number, b: number) => a + b, 0)
	}

	const resetGame = () => {
		const specialistList: any = getLocalValue('specialist');
		const result = specialistList.pacient.reduce((accumulator: any, currentValue: any) => {
			return accumulator + currentValue.points
		}, 0)
		const dataLocal = JSON.parse(value);
		const specialist = dataLocal.specialist.find((spec: any) => spec.used === true);
		specialist.pacient = [];
		setValue(JSON.stringify(dataLocal));

		trackEvent({
			category: 'reset_game',
			action: 'reset_game',
			value: result
		})
	}

	return {
		getLocalValue,
		setLocalValue,
		getLocalCalculate,
		setLocalResult,
		setLocalDopPoints,
		resetGame
	};
};
