import { useEffect, useState } from "react";
import "./styles.scss";
import { iSpecialists } from "../../types/interface";

import SelectSpecialists from "./SelectSpecialists";
import SelectPacients from "./SelectPacients";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

function Specialists({ specialists }: iSpecialists) {
	const [specialist, setSpecialist] = useState<null | number>(null);
	const [numbers] = useState(() => specialists.map((spec) => spec.num).join('; '))
	return (
		<>
			{specialist === null ? (
				<SelectSpecialists
					specialists={specialists}
					setSpecialist={setSpecialist}
					numbers={numbers}
				/>
			) : (
				<SelectPacients
					setSpecialist={setSpecialist}
					specialist={specialists[specialist]}
				/>
			)}
			<div className="footer-block">
				<div className="container">
					<p>Для специалистов здравоохранения. Информация не является рекомендацией компании Такеда, рекламой компании или ее продукции, не должна быть основанием для принятия каких-либо решений или осуществления каких-либо действий. Решение о выборе метода лечения конкретного пациента должно приниматься лечащим врачом.</p>
				</div>
			</div>
		</>
	);
}

export default Specialists;
