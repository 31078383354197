import "./styles.scss";
import { iPopups } from "../../../../../../types/interface";


import Finish from "./Finish";
import Table from "./Table";
import Text from "./Text";
import Warning from "./Warning";

interface iDialog {
	openDialog: (boolean: boolean) => void;
	setSowAnswer: (data: any) => void;
	dataPopup: iPopups;
}

function Dialog({ openDialog, dataPopup, setSowAnswer }: iDialog) {
    // console.log(dataPopup)
    
	const RenderPopup = () => {
		switch (dataPopup.type) {
			case "finish":
                return <Finish openDialog={openDialog} setSowAnswer={setSowAnswer} dataPopup={dataPopup} />
			case "table":
                return <Table openDialog={openDialog} dataPopup={dataPopup} />
			case "text":
                return <Text openDialog={openDialog} dataPopup={dataPopup} />
			case "warning":
                return <Warning openDialog={openDialog} dataPopup={dataPopup} />
			default:
				return <p>error</p>;
		}
	};
	return (
		<dialog className="pacient_steps--dialog">
			<article>
				{dataPopup.type !== "finish" && !dataPopup.disableClose ? (
					<div
						className="pacient_steps--dialog--close"
						onClick={() => openDialog(false)}></div>
				) : null}

				<section>
					<div>
						<RenderPopup />
					</div>
				</section>
			</article>
		</dialog>
	);
}

export default Dialog;
