import "./styles.scss";

import { iSpecialist } from "../../../types/interface";
import React, { useEffect, useState } from "react";

import Pacients from "./Pacients";
import Game from "./Game";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

interface iSpecialistObj {
	specialist: iSpecialist;
	setSpecialist(number: null | number): void;
}

function SelectPacients({ specialist, setSpecialist }: iSpecialistObj) {
	const { title, className, description, tooltip, finish, img, pacients, num, name } =
		specialist;
	const [page, setPage] = useState<boolean>(true);
	const [pacient, setPacient] = useState<number | null>(null);
	const toHtml = (text: any) => {
		return { __html: text + "" };
	};
	return (
		<>
			{!page ? (
				<div className="step_game specialist_main">
					<div className="container">
						<h2>{title}</h2>
						<div className="specialist_main__body">
							<div className={`specialist_main__descriptions ${className}`}>
								<div dangerouslySetInnerHTML={toHtml(description)} />
							</div>
							<ul className={`specialist_main__buttons  ${className}`}>
								<li className={className} onClick={() => setPage(true)}>
									<img
										src={require(`../../../assets/images/specialists/${img}`)}
										alt=""
									/>
									<div>
										<span>Принять пациентов</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			) : pacient === null ? (
				<Pacients
					img={img}
					tooltip={tooltip}
					finish={finish}
					pacients={pacients}
					className={className}
					name={name}
					setPacient={setPacient}
					num={num}
					setSpecialist={setSpecialist}
				/>
			) : (
				<Game pacient={pacients[pacient]} setPacient={setPacient} num={num} img={img} />
			)}
		</>
	);
}

export default SelectPacients;
