import "./styles.scss";

import { iPacientObj, iSpecialists } from "../../../../types/interface";
import { useEffect, useState } from "react";

import Map from "./Map";
import Steps from "./Steps";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useSessinStorageHook } from "../../../../hook/sessionStorage";


function Game({ pacient, setPacient, img, num }: iPacientObj) {
    const [map, setMap] = useState<boolean>(true);
    const [step, setStep] = useState<number>();
    const {
        getLocalValue,
    }: any = useSessinStorageHook();
    const { trackPageView, trackEvent } = useMatomo()
    const [data] = useState<iSpecialists>(() => {
        return require("../../../../assets/data.json").game;
    });
    useEffect(() => {
        document.title = 'Game Hunter - ' + pacient.name?.replaceAll("&nbsp;", " ") + ' ' + pacient.age?.replaceAll("&nbsp;", " ");
        trackPageView({
            documentTitle: 'Game Hunter - ' + data.specialists[getLocalValue("specialist").id].name + ' - ' + pacient.name?.replaceAll("&nbsp;", " ") + ' ' + pacient.age?.replaceAll("&nbsp;", " "), // optional
            customDimensions: [
                {
                    id: 2,
                    value: data.specialists[getLocalValue("specialist").id].name,
                },
                {
                    id: 3,
                    value: pacient.name?.replaceAll("&nbsp;", " ") + ' ' + pacient.age?.replaceAll("&nbsp;", " ")
                },
            ], // optional
        })
    }, []);
    const onStepActive = (number: number) => {
        if (setStep) setStep(number);
        document.title = 'Game Hunter - ' + pacient.name?.replaceAll("&nbsp;", " ") + ' ' + pacient.age?.replaceAll("&nbsp;", " ");
    }
    return (
        map ?
            <Map pacient={pacient} num={num} setPacient={setPacient} setMap={setMap} onStepActive={onStepActive} />
            :
            <Steps pacient={pacient} num={num} setPacient={setPacient} setMap={setMap} step={step} img={img} onStepActive={onStepActive} />
    );
}

export default Game;
