import "./styles.scss";
import { iPopups } from "../../../../../../../types/interface";

interface iDialog {
	openDialog: (boolean: boolean) => void;
	dataPopup: iPopups;
}

function Text({ dataPopup, openDialog }: iDialog) {
	const toHtml = (text: any) => {
		return { __html: text + "" };
	};
	// console.log(dataPopup);

	const RenderContentPopup = ({ objects }: any) => {
		// console.log(objects);
		return objects.map((item: any, index: number) => {
			switch (item.tag) {
				case "p":
					return (
						<p key={index} dangerouslySetInnerHTML={toHtml(item.text)}></p>
					);
				case "ul":
					return (
						<ul key={index}>
							{item.text.map((li: any, index: number) => {
								return (
									<li key={index}>
										{li.img.length
											? li.img.map((img: string | boolean, index: number) => {
													return (
														<i key={index} className={img ? '' : 'hidden-image'}>
															{img ? (
																<img
																	src={require(`../../../../../../../assets/images/specialists/popups/${img}`)}
																	alt=""
																/>
															) : null}
														</i>
													);
											  })
											: null}
										<span dangerouslySetInnerHTML={toHtml(li.li)}></span>
									</li>
								);
							})}
						</ul>
					);
				case "div":
					return (
						<div
                            key={index}
							className={`pacient_steps--dialog__text--content-${item.className}`}>
							<div dangerouslySetInnerHTML={toHtml(item.text)}></div>
						</div>
					);
				case "button":
					return (
						<div
                            key={index}
                            onClick={() => openDialog(false) }
							className={`pacient_steps--dialog__text--content-${item.className}`}>
							<span dangerouslySetInnerHTML={toHtml(item.text)}></span><i></i>
						</div>
					);
				default:
					return <p>err</p>;
			}
		});
	};
	return (
		<div className={`pacient_steps--dialog__text ${dataPopup.className}`}>
			<div className="pacient_steps--dialog__text--title">
                {
                    dataPopup.description.titleImg ? <i>
					<img
						src={require(`../../../../../../../assets/images/specialists/popups/${dataPopup.description.titleImg}`)}
						alt=""
					/>
				    </i> : null
                }
				
				<b>{dataPopup.description.title}</b>
			</div>
			<div className="pacient_steps--dialog__text--content">
				<div>
					<RenderContentPopup objects={dataPopup.description.content} />
				</div>
				<div>
					<RenderContentPopup objects={dataPopup.description.footerContent} />
				</div>
			</div>
		</div>
	);
}

export default Text;
