import "./styles.scss";
import { useState } from "react";
import { useSessinStorageHook } from "../../../../../../../hook/sessionStorage";
import { iPopups } from "../../../../../../../types/interface";

interface iDialog {
    openDialog: (boolean: boolean) => void;
    setSowAnswer: (data: any) => void;
    dataPopup: iPopups;
}

function Finish({ openDialog, dataPopup, setSowAnswer }: iDialog) {
    const {/* setLocalValue, getLocalValue,*/ getLocalCalculate }: any = useSessinStorageHook();
    const [warning, setWarning] = useState<boolean>(false);

    const onClickButton = (button: any, num: any) => {
        let result = 0;
        if (!button.points) {
            setWarning(true);
            return;
        } else {
            result = typeof button.points == 'string' && warning ? 0 : (Number(button.points.split('x')[1]) * getLocalCalculate()) - getLocalCalculate();
        }
        //console.log(result)
        setSowAnswer({ results: result, diagnose: button.diagnose })
        // setLocalValue({ results: result, diagnose: button.diagnose });
        openDialog(false)
    }
    return (
        <div className="pacient_steps--dialog__finish">
            <div className="pacient_steps--dialog__finish--specialist_img">
                <img
                    src={require(`../../../../../../../assets/images/specialists/${dataPopup.description.titleImg}`)}
                    alt=""
                />
                <div className={`pacient_steps--dialog__finish--specialist_img_tool ${warning ? 'warning' : ''}`}>
                    {
                        warning ? <p>Не совсем так</p> : <b>!</b>
                    }
                </div>
            </div>
            <div className="pacient_steps--dialog__finish--buttons">
                <div className="pacient_steps--dialog__finish--buttons_title">
                    {dataPopup.description.title}
                </div>
                <ul>
                    {dataPopup.buttons.map((button: any, index: number) => {
                        return (
                            <li key={index} onClick={() => onClickButton(button, index)}>
                                <span>{button.name}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Finish;