import "./styles.scss";

import { iPacient } from "../../../../../../types/interface";
import { useSessinStorageHook } from "../../../../../../hook/sessionStorage";
import { useEffect, useState } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

interface iInfoPatientStep {
	pacient: iPacient;
	step: number | undefined;
	img?: string;
	setPopup: (number?: number) => void;
	saveClickAnsver: (data: any) => void;
	openDialog: (boolean: boolean) => void;
	onStepNext: (number: number) => void;
	setPacient: (number: number | null) => void;
}

function Ansvers({
	onStepNext,
	pacient,
	step,
	setPopup,
	saveClickAnsver,
	openDialog,
	setPacient,
	img,
}: iInfoPatientStep) {
	const {
		//setLocalValue,
		getLocalValue,
		getLocalCalculate,
		setLocalResult,
		setLocalDopPoints,
	}: any = useSessinStorageHook();
	const toHtml = (text: any) => {
		return { __html: text + "" };
	};

	const results: any = [];
	const { trackPageView, trackEvent } = useMatomo()
	const onClickAnsver = (
		number: number,
		popup: number | undefined,
		point: any
	) => {
		if (typeof point === "object") {
			for (let i = 0; i < point.length; i++) {
				const isAnsv = getLocalValue("step").ansver.length;
				if (isAnsv === 0) {
					if (point[i][0] === 0)
						point =
							typeof point[i][1] == "string"
								? Number(point[i][1].split("x")[1]) * getLocalCalculate() -
								getLocalCalculate()
								: point[i][1];
				} else if (isAnsv === point[i][0]) {
					point =
						typeof point[i][1] == "string"
							? Number(point[i][1].split("x")[1]) * getLocalCalculate() -
							getLocalCalculate()
							: point[i][1];
				} else {
					point = 0;
				}
			}
		}
		// alert(0)
		//console.log({ ansver: number, point: point });
		if (pacient.steps?.length && step !== undefined) {
			trackEvent({
				category: 'answer_game',
				action: 'answer_game',
				name: pacient.steps[step].survey[number].name?.replaceAll("&nbsp;", " ")
			})
		}
		// setLocalValue({ ansver: number, point: point });

		saveClickAnsver({ ansver: number, point: point });
		setPopup(popup);
		openDialog(true);
	};
	const onClickDopPoints = (points: number, popup: number) => {
		setLocalDopPoints(points);
		setPopup(popup);
		openDialog(true);
	};
	const [warning, setWarning] = useState<boolean>(false);

	useEffect(() => {
		// return () => {
		if (pacient.steps?.length && step !== undefined) {
			// console.log(pacient.steps[step].warning);
			if (!warning) {
				setPopup(pacient.steps[step].warning);
				openDialog(true);
				setWarning(true);
			}
		}
		// };
	}, [openDialog, pacient.steps, setPopup, step, warning]);

	const ArrIco = ({ cheak }: any) => {
		return !cheak ? (
			<svg
				width="8"
				height="11"
				viewBox="0 0 8 11"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M2.4082 2.25L5.88189 5.72368L2.4082 8.90789"
					stroke="white"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		) : (
			<svg
				width="14"
				height="11"
				viewBox="0 0 14 11"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12 2L6.28571 9L2 4.88235"
					stroke="#3B989B"
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		);
	};

	if (pacient.steps && step !== undefined) {
		if (
			pacient.steps[step].preQuestion &&
			getLocalValue("step").dopPoints === false
		) {
			//console.log(getLocalValue("step"));
			pacient.steps[step].preQuestion.survey.map((que: any, index: number) => {
				results.push(
					<li
						className={
							getLocalValue("step").ansver.find(
								(ansv: any) => ansv.id === index
							)
								? "active"
								: ""
						}
						key={index}
						onClick={() => onClickDopPoints(que.points, que.popup.index)}>
						<b dangerouslySetInnerHTML={toHtml(que.name)}></b>
						<i>
							<ArrIco cheak={false} />
						</i>
					</li>
				);
				return false;
			});
		} else {
			pacient.steps[step].survey.map((sur, index) => {
				results.push(
					<li
						className={
							getLocalValue("step").ansver.find(
								(ansv: any) => ansv.id === index
							)
								? "active"
								: ""
						}
						key={index}
						onClick={() => onClickAnsver(index, sur.popup.index, sur.points)}>
						<b dangerouslySetInnerHTML={toHtml(sur.name)}></b>
						<i>
							<ArrIco
								cheak={
									getLocalValue("step").ansver.find(
										(ansv: any) => ansv.id === index
									)
										? true
										: false
								}
							/>
						</i>
					</li>
				);
				return false;
			});
		}
	}
	const onClickFinish = () => {
		setLocalResult({
			points: getLocalCalculate(),
			diagnose: getLocalValue("results").diagnose,
		});
		setPacient(null);
	};
	const isAnsverUsed = (index: number) => {
		const result = getLocalValue("pacient")?.step.find(
			(step: any) => step.id === index
		);
		if (
			pacient.steps &&
			step !== undefined &&
			result.ansver.length === pacient.steps[step].survey.length
		) {
			return true;
		}
		return false;
	};
	return (
		<div className="pacient_steps--content-ansvers">
			<div
				className={`pacient_steps--content-ansvers-block ${pacient.steps && step !== undefined && pacient.steps[step].className
					? pacient.steps[step].className
					: ""
					}`}>
				{pacient.steps && pacient.steps?.length - 1 !== step ? (
					<div className="pacient_steps--content-ansvers__title">
						<b>
							{getLocalValue("step").dopPoints === false &&
								step !== undefined &&
								pacient.steps[step].preQuestion
								? "Что-то настораживает?"
								: "Назначить обследование:"}
						</b>
					</div>
				) : null}
				{getLocalValue("results") &&
					getLocalValue("results").diagnose &&
					pacient.steps &&
					pacient.steps?.length - 1 === step ? (
					<>
						<div className="pacient_steps--content-ansvers__doctor doctor_result">
							<div className="pacient_steps--content-ansvers__doctor__tooltip">
								<b
									dangerouslySetInnerHTML={toHtml(
										pacient.steps[step].resultText
									)}></b>
							</div>
							<img
								src={require(`../../../../../../assets/images/specialists/result-${img}`)}
								alt=""
							/>
						</div>
						<div className="pacient_steps--content-ansvers__result">
							<div
								dangerouslySetInnerHTML={toHtml(
									getLocalValue("results").diagnose
								)}></div>
							<ul>
								<li onClick={() => onClickFinish()}>
									Посмотреть мой результат
									<i>
										<ArrIco />
									</i>
								</li>
							</ul>
						</div>
					</>
				) : (
					<>
						{pacient.steps && step !== undefined ? (
							pacient.steps[step].finish ? (
								<div className="pacient_steps--content-ansvers__doctor">
									<div className="pacient_steps--content-ansvers__doctor__tooltip">
										<span
											dangerouslySetInnerHTML={toHtml(
												pacient.steps[step].finishText
											)}></span>
									</div>
									<img
										src={require(`../../../../../../assets/images/specialists/diagnosis-${img}`)}
										alt=""
									/>
								</div>
							) : null
						) : null}
						<ul>{results}</ul>
					</>
				)}
			</div>
			{pacient.steps && pacient.steps?.length - 1 !== step ? (
				<div
					onClick={() =>
						step !== undefined && isAnsverUsed(step) ? onStepNext(step) : null
					}
					className={`pacient_steps--content-ansvers-next ${step !== undefined && isAnsverUsed(step) ? "" : "disabled"
						}`}>
					<span>Далее</span>
					<i></i>
				</div>
			) : null}
		</div>
	);
}

export default Ansvers;
