import "./styles.scss";
import { useSessinStorageHook } from "../../../../../../hook/sessionStorage";

interface iStepParam {
	keyPoint: string;
	stepName: string;
	active: boolean;
	number: number
	mapFalse: (boolean: boolean) => void
	stepActive: (number: number) => void
	isMobile: boolean
}

function Step(param: iStepParam) {
	const { setLocalValue } = useSessinStorageHook();
	const { keyPoint, active, number, mapFalse, stepActive, stepName } = param;
	//console.log("Step "+number+": "+param.keyPoint)

	const onClickStep = () => {
		if (active) {
			setLocalValue({ "step": number - 1 });
			mapFalse(false);
			stepActive(number - 1);
		}
	}

	return (
		<g className={`pacient_map--step ${active ? "active" : ""}`} onClick={onClickStep}>
			<circle r={param.isMobile ? 64 : 70} fill={active ? "#44ACAE" : "#7879F1"}></circle>
			<circle
				r={param.isMobile ? 53 : 56}
				stroke="white"
				strokeWidth="3"
				fill={active ? "#3B989B" : "#5D5FEF"}></circle>
			<text
				textAnchor="middle"
				className="pacient_map--step__number"
				fill="#fff">
				{number}
			</text>
			<text
				y={active ? "28" : "22"}
				textAnchor="middle"
				className="pacient_map--step__label"
				fill="#fff">
				{stepName}
			</text>
			{active ? null : (
				<g transform="translate(-6, 28)">
					<path
						d="M11.591 6.43278V4.91231C11.591 2.57313 9.77282 0.701782 7.50009 0.701782C5.22736 0.701782 3.40918 2.57313 3.40918 4.91231V6.43278"
						stroke="white"
						strokeWidth="1.2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M14.318 19.2982H2.04528C1.24982 19.2982 0.681641 18.5965 0.681641 17.8947V6.43274H12.9544C13.7498 6.43274 14.318 7.13449 14.318 7.83625V19.2982Z"
						stroke="white"
						strokeWidth="1.2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7.50027 13.4502C8.0651 13.4502 8.52299 12.979 8.52299 12.3976C8.52299 11.8163 8.0651 11.345 7.50027 11.345C6.93543 11.345 6.47754 11.8163 6.47754 12.3976C6.47754 12.979 6.93543 13.4502 7.50027 13.4502Z"
						fill="white"
					/>
					<path
						d="M7.84109 15.6725H7.15927C7.04563 15.6725 6.81836 15.5556 6.81836 15.3217V12.8655C6.81836 12.7486 6.932 12.5146 7.15927 12.5146H7.84109C7.95472 12.5146 8.182 12.6316 8.182 12.8655V15.4386C8.182 15.5556 8.06836 15.6725 7.84109 15.6725Z"
						fill="white"
					/>
				</g>
			)}

			<animateMotion
				calcMode="linear"
				keyPoints={keyPoint}
				keyTimes="0; 1">
				<mpath xlinkHref="#curve" />
			</animateMotion>
		</g>
	);
}

export default Step;
